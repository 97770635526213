/*
WeddingSpot themed rc-tooltip. This style is loosely based on the hb_scss light theme.
*/

/* START Base styles published with rc-tooltip package */

@keyframes rcToolTipZoomIn {
    0% {
      opacity: 0;
      transform-origin: 50% 50%;
      transform: scale(0, 0);
    }
    100% {
      opacity: 1;
      transform-origin: 50% 50%;
      transform: scale(1, 1);
    }
  }
  @keyframes rcToolTipZoomOut {
    0% {
      opacity: 1;
      transform-origin: 50% 50%;
      transform: scale(1, 1);
    }
    100% {
      opacity: 0;
      transform-origin: 50% 50%;
      transform: scale(0, 0);
    }
}

.rc-tooltip,
.rc-tooltip__dark {

    position: absolute;
    z-index: 1070;
    display: block;
    visibility: visible;
    line-height: 1.5;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 1px;
    opacity: 0.9;

    /*
    Not sure if this is being used.. it causes problems with the .rc-tooltip and .rc-tooltip__dark selector
    by doubling the specificity (e.g. .rc-tooltip.rc-tooltip) which causes the tooltip to not hide when hidden

    &#{&}-zoom-enter,
    &#{&}-zoom-leave{
        display: block;
    }
    */

    &-zoom-enter,
    &-zoom-appear {
        opacity: 0;
        animation-duration: 0.3s;
        animation-fill-mode: both;
        animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
        animation-play-state: paused;
    }
    &-zoom-leave {
        animation-duration: 0.3s;
        animation-fill-mode: both;
        animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
        animation-play-state: paused;
    }
    &-zoom-enter#{&}-zoom-enter-active,
    &-zoom-appear#{&}-zoom-appear-active {
        animation-name: rcToolTipZoomIn;
        animation-play-state: running;
    }
    &-zoom-leave#{&}-zoom-leave-active {
        animation-name: rcToolTipZoomOut;
        animation-play-state: running;
    }

    &-hidden {
        display: none;
    }
    &-inner {
        padding: 8px 10px;
        color: #333333;
        text-align: left;
        text-decoration: none;
        background-color: #ffffff;
        border-radius: 3px;
        min-height: 34px;
        border: 1px solid #b1b1b1;
    }
    &-arrow,
    &-arrow-inner {
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }
    &-placement-top &-arrow,
    &-placement-topLeft &-arrow,
    &-placement-topRight &-arrow {
        bottom: -5px;
        margin-left: -6px;
        border-width: 6px 6px 0;
        border-top-color: #b1b1b1;
    }
    &-placement-top &-arrow-inner,
    &-placement-topLeft &-arrow-inner,
    &-placement-topRight &-arrow-inner {
        bottom: 1px;
        margin-left: -6px;
        border-width: 6px 6px 0;
        border-top-color: #ffffff;
    }
    &-placement-top &-arrow {
        left: 50%;
    }
    &-placement-topLeft &-arrow {
        left: 15%;
    }
    &-placement-topRight &-arrow {
        right: 15%;
    }
    &-placement-right &-arrow,
    &-placement-rightTop &-arrow,
    &-placement-rightBottom &-arrow {
        left: -5px;
        margin-top: -6px;
        border-width: 6px 6px 6px 0;
        border-right-color: #b1b1b1;
    }
    &-placement-right &-arrow-inner,
    &-placement-rightTop &-arrow-inner,
    &-placement-rightBottom &-arrow-inner {
        left: 1px;
        margin-top: -6px;
        border-width: 6px 6px 6px 0;
        border-right-color: #ffffff;
    }
    &-placement-right &-arrow {
        top: 50%;
    }
    &-placement-rightTop &-arrow {
        top: 15%;
        margin-top: 0;
    }
    &-placement-rightBottom &-arrow {
        bottom: 15%;
    }
    &-placement-left &-arrow,
    &-placement-leftTop &-arrow,
    &-placement-leftBottom &-arrow {
        right: -5px;
        margin-top: -6px;
        border-width: 6px 0 6px 6px;
        border-left-color: #b1b1b1;
    }
    &-placement-left &-arrow-inner,
    &-placement-leftTop &-arrow-inner,
    &-placement-leftBottom &-arrow-inner {
        right: 1px;
        margin-top: -6px;
        border-width: 6px 0 6px 6px;
        border-left-color: #ffffff;
    }
    &-placement-left &-arrow {
        top: 50%;
    }
    &-placement-leftTop &-arrow {
        top: 15%;
        margin-top: 0;
    }
    &-placement-leftBottom &-arrow {
        bottom: 15%;
    }
    &-placement-bottom &-arrow,
    &-placement-bottomLeft &-arrow,
    &-placement-bottomRight &-arrow {
        top: -5px;
        margin-left: -6px;
        border-width: 0 6px 6px;
        border-bottom-color: #b1b1b1;
    }
    &-placement-bottom &-arrow-inner,
    &-placement-bottomLeft &-arrow-inner,
    &-placement-bottomRight &-arrow-inner {
        top: 1px;
        margin-left: -6px;
        border-width: 0 6px 6px;
        border-bottom-color: #ffffff;
    }
    &-placement-bottom &-arrow {
        left: 50%;
    }
    &-placement-bottomLeft &-arrow {
        left: 15%;
    }
    &-placement-bottomRight &-arrow {
        right: 15%;
    }
}


/* END Base styles published with rc-tooltip package */


/* START overrides */
.rc-tooltip {
    background-color: white;
    opacity: 1;

    &-inner {
        color: #333333;
        background-color: white;
    }

    &-placement-top &-arrow,
    &-placement-topLeft &-arrow,
    &-placement-topRight &-arrow {
        border-top-color: white;
    }

    &-placement-right &-arrow,
    &-placement-rightTop &-arrow,
    &-placement-rightBottom &-arrow {
        border-right-color: white;
    }

    &-placement-bottom &-arrow,
    &-placement-bottomLeft &-arrow,
    &-placement-bottomRight &-arrow {
        border-bottom-color: white;
    }

    &-placement-left &-arrow,
    &-placement-leftTop &-arrow,
    &-placement-leftBottom &-arrow {
        border-left-color: white;
    }
}

.rc-tooltip__dark {
    background-color: black;
    opacity: 0.8;

    &-inner {
        color: white;
        background-color: black;
    }

    &-placement-top &-arrow,
    &-placement-topLeft &-arrow,
    &-placement-topRight &-arrow {
        border-top-color: black;
    }

    &-placement-right &-arrow,
    &-placement-rightTop &-arrow,
    &-placement-rightBottom &-arrow {
        border-right-color: black;
    }

    &-placement-bottom &-arrow,
    &-placement-bottomLeft &-arrow,
    &-placement-bottomRight &-arrow {
        border-bottom-color: black;
    }

    &-placement-left &-arrow,
    &-placement-leftTop &-arrow,
    &-placement-leftBottom &-arrow {
        border-left-color: black;
    }
}


.rc-tooltip,
.rc-tooltip__dark {
    font-size: 14px;
    max-width: 360px;
    box-shadow: 0 0 20px 0 rgba(0,0,33,0.15), 0 0 0 1px rgba(0,18,71,0);

    &-inner {
        border-radius: 2px;
        border: none;
        text-align: center;
    }
}

/* END overrides */